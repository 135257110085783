import { AppProviderContextType } from './types'

export const mockAppHook = (
  params?: Partial<AppProviderContextType>,
) => {
  jest.spyOn(require('./use-app'), 'useApp').mockImplementation(
    () =>
      ({
        isInitialRouteLoading: true,
        unsupportedAgent: null,
        ...params,
      }),
  )
}
