import { Session } from 'next-auth'
import Chai from 'types/chai'
import { SessionContextValue } from 'next-auth/react'
import { mockConxSdkHook } from 'modules/ConxSdkProvider/mocks'
import { AuthenticationContextType } from 'modules/AuthenticationProvider/types'

export const mockNextAuthSessionHook = (
  params?: Partial<SessionContextValue>,
) => {
  const nextAuthHooks = require('next-auth/react')
  const session = getFakeAuthenticatedNextAuthSessionData()

  jest.spyOn(nextAuthHooks, 'useSession').mockReturnValue({
    data: session,
    status: 'authenticated',
    ...params,
  })

  // Mock the nextauth signout function, as it'll try (and fail) to fetch the session otherwise
  jest.spyOn(nextAuthHooks, 'signOut').mockImplementation()
}

export const mockAuthenticationHook = (
  params?: Partial<AuthenticationContextType>,
) => {
  jest.spyOn(require('./use-authentication'), 'useAuthentication').mockImplementation(
    () =>
      ({
        authCredentials: {
          guid: 'user-guid',
          userAccessToken: 'access-token',
        },
        isSessionLoading: false,
        isValidSession: true,
        onOauthSessionFailureRetry: jest.fn(),
        userSession: getFakeSdkUserSession(),
        ...params,
      } as AuthenticationContextType),
  )
}

export const getFakeAnonymousNextAuthSessionData = (props: Partial<Session> = {}) => ({
  expires: null,
  grantInfo: null,
  ...props,
} as Session)

export const getFakeAuthenticatedNextAuthSessionData = (props: Partial<Session> = {}) => ({
  expires: (new Date()).toISOString(),
  grantInfo: {
    accessToken: 'eyJhbGciOiJFUzUxMiJ9.eyJpc3MiOiJKdXVsaW8iLCJpYXQiOjE2NTA1MzI3MDEsImp0aSI6IkljamIzNktHYjQrUndSNE5xeTZIZGc9PSIsImV4cCI6MTY4MjA2ODcwMSwic3ViIjoiMTIyNyIsInNjb3BlcyI6Im1vYmlsZWFwcCJ9.AXh1roHXOnj30CPyLfcIUKaD5wbI2BbxIXkWXgkI3hlH0MzNeAdAQqFQ5JtWpLcgg8iVuJdDpcSCbUHKkTJXLUrdAC47fzn_4_u-27KxXOF77JFFVlbfUchhsYyQIZyfSKEUGpZWOsnYhfDZV9XWPwxuHRgZOuMxAHbRIDs6ii6gNak6',
    createdAt: (new Date()).toISOString(),
    expiresIn: 31536000,
    guid: 'user-guid',
    keychain: {
      private_key: {
        server_ec_bn_s: 'BO2kM51BM1R9uun/C8EhYbYeqwr5iNyVI/PoT4E+bT30yNYascdcx+jiOlRarCCq/NFLL3Xz7Y26t6AI0qrhl2s=',
        tag: 'DNWmQSBKyJ/3a6ydxo7NJA==',
        value: 'Wr29n5r7dYbAqUCdZKEYEn8v0fuwC5pciBO25pESxSAch8wLUq44Yhxv0MwyIXvbHNUZn+L6WyycHL/flI6CwWVnvl+f3FjyeSD9QnzIBinP1nB7l7b5Xsm1lmoYYohKxWWDD/9hA10CqK8EzN6ijzzL0E63TVqOBw==',
      },
      public_key: 'BAx0f54sBOz5NQlxyzlvbGeljOrWqZQtYKOv/XAYBety4w8l95CknEpi9iDZAq8/q2QJLbVftiQRwt8esNCS01c=',
    },
    refreshToken: '',
    scope: 'mobileapp conx_web',
    tokenType: 'bearer',
  },
  ...props,
} as Session)


export const mockSdkUserSessionInitializer = (
  params?: Partial<Chai.UserSession>,
) => {
  const originalChaiUserSession = Chai.UserSession

  beforeEach(() => {
    mockConxSdkHook()
    Chai.UserSession = jest.fn().mockReturnValue(
      getFakeSdkUserSession(params),
    )
  })

  afterEach(() => {
    Chai.UserSession = originalChaiUserSession
  })
}

export const getFakeSdkUserSession = (params?: Partial<Chai.UserSession>) => ({
  clearCredentials: jest.fn().mockResolvedValue(null),
  end: jest.fn().mockResolvedValue(null),
  getOAuthKey: jest.fn().mockResolvedValue('oauthKey'),
  isActive: true,
  isActiveWithKeyChain: true,
  isSessionActive: true,
  setCredentials: jest.fn().mockResolvedValue(null),
  ...params,
} as Chai.UserSession)
