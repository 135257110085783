import { satisfies } from 'semver'
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import { acceptedCountryCodesSet } from 'types/regions'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { useAuthentication } from 'modules/AuthenticationProvider'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import {
  AppProviderContextType,
  COMPATIBLE_BLUEFY_VERSION,
  COMPATIBLE_CHROME_VERSION,
  UnsupportedAgentType,
} from './types'
import { WebAppScreens } from 'analytics-events'
import { useStorageObserver } from 'modules/AppProvider/hooks'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { signOut as nextAuthSignOut } from 'next-auth/react'
import { bugsnagClient } from 'utils/bugsnag'

// todo add test cases

export const AppProviderContext = createContext<AppProviderContextType | null>({
  isInitialRouteLoading: true,
  unsupportedAgent: null,
})

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  // hooks
  const { userAgent, region } = useConxSdk()
  const {
    isSessionLoading,
    isValidSession,
  } = useAuthentication()
  const { analytics, trackLocalStorageCleared } = useAnalytics()
  const {
    pathname,
    push: pushRoute,
    query,
  } = useRouter()
  const {
    gadgetsList,
    isGadgetsReady,
  } = useDeviceConnection()

  // state
  const [isInitialRouteLoading, setIsInitialRouteLoading]
    = useState<boolean>(true)
  const [unsupportedAgent, setUnsupportedAgent]
    = useState<UnsupportedAgentType>(null)
  const [hasProcessedUserAgent, setHasProcessedUserAgent]
    = useState<boolean>(false)

  /**
   * Event handler for a cache clear event
   */
  const onClearLocalStorage = useCallback(async() => {
    trackLocalStorageCleared()

    await nextAuthSignOut({ redirect: false })
  }, [analytics])

  /** OBSERVERS */
  useStorageObserver(onClearLocalStorage)

  /**
   * set unsupported browser version
   */
  useEffect(() => {
    if (!userAgent) return

    if (userAgent.isBluefy) {
      if (!satisfies(userAgent.bluefyVersion, COMPATIBLE_BLUEFY_VERSION)) {
        setUnsupportedAgent(WebAppScreens.Name.BluefyRedirect)
      }
    } else {
      if (userAgent.isMobile || userAgent.isTablet) {
        if (userAgent.isAndroid) {
          setUnsupportedAgent(WebAppScreens.Name.AndroidRedirect)
        } else if (userAgent.isIos || userAgent.isMac) {
          setUnsupportedAgent(WebAppScreens.Name.IosRedirect)
        } else {
          setUnsupportedAgent(WebAppScreens.Name.UnsupportedDevice)
          bugsnagClient?.notify?.({ message: JSON.stringify(userAgent), name: 'unusual-device' })
        }
      } else if (!userAgent.isChrome) {
        setUnsupportedAgent(WebAppScreens.Name.ChromeRedirect)
      } else if (userAgent.isChrome
        && userAgent.browserVersion < COMPATIBLE_CHROME_VERSION) {
        setUnsupportedAgent(WebAppScreens.Name.ChromeRedirect)
      }
    }

    setHasProcessedUserAgent(true)
  }, [userAgent])

  /**
   * set the initial route
   */
  useEffect(() => {
    if (isSessionLoading
      || !isInitialRouteLoading
      || !hasProcessedUserAgent
    ) return

    const handlePushRoute = async(args) => {
      await pushRoute(args)
      setIsInitialRouteLoading(false)
    }

    if (!acceptedCountryCodesSet.has(region)) {
      handlePushRoute(Routes.UnsupportedRegion)
      return
    }

    if (unsupportedAgent) {
      handlePushRoute(Routes.UnsupportedAgent)
      return
    }

    if (isValidSession) {
      if (isGadgetsReady) {
        if (!gadgetsList?.length) {
          handlePushRoute(Routes.Pair)
        } else if (pathname !== Routes.UsageInsights
          && pathname !== Routes.Settings) {
          handlePushRoute(Routes.Home)
        } else {
          handlePushRoute(pathname)
        }
      }
    } else {
      // Maintain any query params as the oauth callback may contain an error
      handlePushRoute({ pathname: Routes.Welcome, query })
    }
  }, [hasProcessedUserAgent, isGadgetsReady, isSessionLoading, isValidSession])

  const values: AppProviderContextType = {
    isInitialRouteLoading,
    unsupportedAgent,
  }

  return (
    <AppProviderContext.Provider value={values}>
      {children}
    </AppProviderContext.Provider>
  )
}
