import React, { HTMLAttributes } from 'react'

export type Entries = {
  [x: string]: string | React.ReactNode
}

export type HTMLTags = 'span' | 'div' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  | 'h6' | 'strong' | 'em' | 'b' | 'i' | 'u' | 'mark' | 'cite' | 'dfn' | 'small'
  | 'del' | 'ins' | 'sub' | 'sup' | 'br' | 'pre' | 'code' | 'samp' | 'kbd'
  | 'var' | undefined

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  className?: string
  content?: string
  tagName?: HTMLTags
  values?: Entries
}
