import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { Reoverlay } from 'reoverlay'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'
import { useUserAgent } from 'next-useragent'
import { useLocalStorage } from 'hooks'
import Chai from 'types/chai'
import { bugsnagClient } from 'utils/bugsnag'
import { SDKCrashModal } from './data'
import {
  UserAgentType,
  ConxSdkProviderContextType,
  ConxSdkProviderProps,
} from './types'
import {
  ChaiConfig,
  newChaiContext,
  installChaiCrashlytics,
  USER_CLIENT_ID_KEY,
} from './utils'
import { getIsPreProdEnvironment, BLUEFY_REGEX, getIsDebugMode } from 'utils/app'

export const ConxSdkProviderContext =
  createContext<ConxSdkProviderContextType | null>({
    host: null,
    isNewClient: null,
    isPodPullPairingEnabled: null,
    isStudyModeEnabled: null,
    locale: null,
    region: null,
    sdkContext: null,
    userAgent: null,
    userClientId: null,
  })

export const ConxSdkProvider: FC<PropsWithChildren<ConxSdkProviderProps>> = ({
  appHost,
  children,
  config = ChaiConfig,
  userRegion,
}) => {
  const router = useRouter()

  // state
  const [isNewClient, setIsNewClient] = useState<boolean>(false)
  const [isPodPullPairingEnabled, setIsPodPullPairingEnabled] = useState<boolean>(null)
  const [isStudyModeEnabled, setIsStudyModeEnabled] = useState<boolean>(null)
  const [locale, setLocale] = useState<string>(null)
  const [region, setRegion] = useState<string>(userRegion)
  const [host, setHost] = useState<string>(appHost)
  const [sdkContext, setSdkContext] = useState<Chai.Context>()
  const [userAgent, setUserAgent] = useState<UserAgentType>(null)
  const [userClientId, setUserClientId] = useState<string>('')

  // local storage
  const [cachedUserClientId, setCachedUserClientId]
    = useLocalStorage<string>(USER_CLIENT_ID_KEY, '')

  const initializeSdkContext = async(contextConfig: Chai.ContextConfig) => {
    const context = await Chai.createContext(contextConfig, handleSDKCrash)
    installChaiCrashlytics()
    return context
  }

  const handleSDKCrash = (error: Error) => {
    Reoverlay.showModal('TextModal', {
      ...SDKCrashModal,
      description: [
        ...SDKCrashModal.description,
        ...(getIsPreProdEnvironment() ? [{ content: `${error}` }] : []),
      ],
      primaryButton: {
        onClick: router.reload,
        ...SDKCrashModal.primaryButton,
      },
    })
    bugsnagClient?.notify?.({
      message: `${error}`,
      name: 'conxsdk_context_halted',
    })
  }

  /**
   * Manage user-client ID sync with localStorage and Bugsnag.
   */
  useEffect(() => {
    let uid: string

    if (!userClientId && cachedUserClientId) {
      uid = cachedUserClientId
      setUserClientId(uid)
      setIsNewClient(false)
    } else if (userClientId) {
      uid = userClientId
      setCachedUserClientId(uid)
    } else {
      uid = uuidv4()
      setUserClientId(uid)
      setCachedUserClientId(uid)
      setIsNewClient(true)
    }

    const contextConfig = newChaiContext(uid, config)

    initializeSdkContext(contextConfig).then(
      (context) => {
        setSdkContext(context)
        getIsDebugMode() && (window.sdkContext = context)
      },
    )
  }, [])

  /**
   * set the locale and the userAgent.
   */
  useEffect(() => {
    // set locale
    setLocale(window.navigator.language)

    // set region
    setRegion(userRegion)

    // set host
    setHost(appHost)

    // parse and set user agent
    const userAgentActual = useUserAgent(window.navigator.userAgent)
    const bluefy = userAgentActual.source.match(BLUEFY_REGEX)
    setUserAgent({
      ...userAgentActual,
      bluefyVersion: bluefy?.[1],
      isBluefy: Boolean(bluefy),
    })
  }, [])

  /**
   * add listeners for remote config values
   */
  useEffect(() => {
    if (!sdkContext) return

    sdkContext.remoteConfig?.podPullPairingEnabled.addListener(
      setIsPodPullPairingEnabled,
    )
    sdkContext.remoteConfig?.studyModeEnabled.addListener(
      setIsStudyModeEnabled,
    )

    return () => {
      sdkContext.remoteConfig?.podPullPairingEnabled.removeListener(
        setIsPodPullPairingEnabled,
      )
      sdkContext.remoteConfig?.studyModeEnabled.removeListener(
        setIsStudyModeEnabled,
      )
    }
  }, [sdkContext])

  const values: ConxSdkProviderContextType = {
    host,
    isNewClient,
    isPodPullPairingEnabled,
    isStudyModeEnabled,
    locale,
    region,
    sdkContext,
    userAgent,
    userClientId,
  }

  return (
    <ConxSdkProviderContext.Provider value={values}>
      {children}
    </ConxSdkProviderContext.Provider>
  )
}
