import Routes from 'types/routes'
import {
  getIsEUTEnvironment,
  getIsPreProdEnvironment,
  getIsVercelProdEnvironment,
} from 'utils/app'

// env
// isEUTEnvironment affects vercel EUT URLs only, no relation to study mode via the SDK.
const isEUTEnvironment = getIsEUTEnvironment()
const isPreProdEnvironment = getIsPreProdEnvironment()
const isVercelProdEnvironment = getIsVercelProdEnvironment()

// favicon utils
// preprod will use the production favicon
export enum FaviconEnv {
  Dev = 'dev',
  Prod = 'prod',
  Study = 'study',
 }
export const getFaviconEnv = ():FaviconEnv => {
  if (isEUTEnvironment) return FaviconEnv.Study
  else if (isVercelProdEnvironment) return FaviconEnv.Prod
  else return FaviconEnv.Dev
}

// page and app title utils
enum AppTitleBase {
  Default = 'JUUL App',
  Study = 'JUUL Study',
}
enum AppTitleSuffix {
  Dev = 'Develop',
  PreProd = 'PreProd',
}
export const RouteTitle: Record<Routes, string> = {
  [Routes.Diagnostics]: 'Diagnostics',
  [Routes.FirmwareUpdate]: 'Firmware Update',
  [Routes.Home]: '',
  [Routes.LongTermTrends]: 'Long Term Trends',
  [Routes.Pair]: 'Pair',
  [Routes.Pairing]: 'Pairing',
  [Routes.Settings]: 'Settings',
  [Routes.ShortTermTrends]: 'Short Term Trends',
  [Routes.UnsupportedAgent]: 'Unsupported Agent',
  [Routes.UnsupportedRegion]: 'Unsupported Region',
  [Routes.UsageInsights]: 'Usage Insights',
  [Routes.Welcome]: 'Welcome',
}

export const getAppTitle = (): string => {
  const titleBase = isEUTEnvironment ? AppTitleBase.Study : AppTitleBase.Default
  let titleSuffix = null

  // NEXT_PUBLIC_APP_TITLE should be used customize the end of the base title
  // ex. NEXT_PUBLIC_APP_TITLE = QA1, in a non study env title will equal 'JUUL App QA1'
  if (process.env.NEXT_PUBLIC_APP_TITLE) {
    titleSuffix = process.env.NEXT_PUBLIC_APP_TITLE
  } else if (!isVercelProdEnvironment) {
    titleSuffix = AppTitleSuffix.Dev
  } else if (isVercelProdEnvironment && isPreProdEnvironment) {
    titleSuffix = AppTitleSuffix.PreProd
  }

  return titleSuffix ? titleBase.concat(' ', titleSuffix) : titleBase
}

export const getPageTitle = (pathname: string): string => {
  const appTitle = getAppTitle()
  const page = RouteTitle[pathname] || null

  return page ? page.concat(' | ', appTitle) : appTitle
}
