// eslint-disable-next-line
import * as hooks from 'modules/AnalyticsProvider/use-analytics'
import { AnalyticsContextType } from './types'

export const mockAnalyticsHook = (params?: Partial<AnalyticsContextType>) => {
  jest.mock('modules/AnalyticsProvider/utils', () => ({ getAppVersion: jest.fn(() => '1.9.0') }))
  jest.spyOn(hooks, 'useAnalytics').mockImplementation(
    () =>
      ({
        analytics: jest.fn(),
        analyticsIdentify: jest.fn(),
        analyticsIdentifyReset: jest.fn(),
        reportEvent: jest.fn(),
        trackClickEvent: jest.fn(),
        trackCloudSyncOptionEvent: jest.fn(),
        trackEvent: jest.fn(),
        trackSignIn: jest.fn(),
        ...params,
      } as AnalyticsContextType),
  )
}
